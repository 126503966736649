import Autocomplete from './libs/autocomplete.esm.js';
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import {addClass, removeClass, find, on} from '@elements/dom-utils';
import axios from "axios";

const selectors = {
    base: '.js-autocomplete',
};

// the input with the base class un-/checks all other inputs with the child class that are in the container
export function init() {
    onFind(selectors.base, (autocompleteEl) => {

        let autocompleteType = autocompleteEl.dataset.autocompleteType;
        let remoteUrl = autocompleteEl.dataset.autocompleteRemoteUrl;
        let frequentylRemoteUrl = autocompleteEl.dataset.autocompleteFrequentlyRemoteUrl;
        let wildcard = "%QUERY%";
        let deleteButton = find('.js-autocomplete__delete');

        on('input', function (evt) {
            if(autocompleteEl.value){
                addClass('d-block', deleteButton);
                removeClass('d-none', deleteButton);
            }else{
                console.log('remove class');
                removeClass('d-block', deleteButton);
                addClass('d-none', deleteButton);
            }
        }, autocompleteEl);

        on('click', function (evt) {
            removeClass('d-block', deleteButton);
            addClass('d-none', deleteButton);
        }, deleteButton);

        // get dataset as object
        let categories = JSON.parse(autocompleteEl.dataset.autocompleteCategories);
        new Autocomplete("mainSearch", {
            clearButton: true,
            howManyCharacters: 3,
            onSearch: ({ currentValue }) => {
                const api = remoteUrl.replace(wildcard, encodeURIComponent(currentValue));

                return axios.get(api)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onFocus: ({ currentValue }) => {
                const frequentlyApi = frequentylRemoteUrl.replace(wildcard, encodeURIComponent(currentValue));
                return axios.get(frequentlyApi)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onResults: ({ currentValue, matches, template, classGroup }) => {

                let bottomItems = [];
                let middleItems = [];
                let topItems = [];
                let isFrequentlySearched = matches.isFrequentlySearched || false;
                // loop through the categories and create the result items
                categories.map((category) => {
                    let categoryItems = matches[category.name];

                    if (categoryItems) {

                        let tempItems = categoryItems.results.map((data, index, array) => {
                            // create the regex for the highlight
                            let regexp = new RegExp(currentValue, 'gi');

                            if(data){
                                if (data.title) {
                                    if (data.type === "product") {
                                        if(index <= 2){
                                            return `
                                        <div
                                            class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item autocomplete-image stretch-link" 
                                            data-event-tracking-event="search_select"
                                            data-event-tracking-search_string="${currentValue}"
                                            data-event-tracking-clicked_result="${data.title}">
                                            ${ /* hack to show only the title in the autocomplete (e.g. when pressing enter) */'' }
                                            <div hidden>${data.title}</div>
                                            <div class='autocomplete-image__img-container'>
                                                <div class='ratio ratio-1x1'>
                                                    ${ /* use thumbnail "autocomplete-image" */'' }
                                                    <img class="ratio-item" src='${data.imageUrl}' alt="">
                                                </div>
                                            </div>
                                            <div class='autocomplete-image__text'>
                                                <a href="${data.url}" class="font-bold stretch-link__link">${data.title.replace(regexp, '<mark>$&</mark>')}<span class="mt-3 strong">${data.price ? "<br/>" + data.price.replace(regexp, '<mark>$&</mark>') : ""}</span> </a>
                                            </div>
                                        </div>`
                                        }
                                    }if (data.type === "faq") {
                                        if(index <= 2){
                                            return `
                                        <div
                                            class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item autocomplete-image stretch-link" 
                                            data-event-tracking-event="search_select"
                                            data-event-tracking-search_string="${currentValue}"
                                            data-event-tracking-clicked_result="${data.title}">
                                            ${ /* hack to show only the title in the autocomplete (e.g. when pressing enter) */'' }
                                            <div hidden>${data.title}</div>
                                            <div class='autocomplete-image__text'>
                                                <a href="${data.url}" class="font-bold stretch-link__link">
                                                ${data.title.replace(regexp, '<mark>$&</mark>')}
                                                   <div class="autocomplete-image__info-text">
                                                        <span> ${data.description ? data.description.replace(regexp, '<mark>$&</mark>') : ""}</span> 
                                                    </div>
                                                 </a>
                                            </div>
                                        </div>`
                                        }
                                    }else if (data.type === "blog") {
                                        if(index <= 2){
                                            return `
                                        <div
                                            class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item autocomplete-image stretch-link" 
                                            data-event-tracking-event="search_select"
                                            data-event-tracking-search_string="${currentValue}"
                                            data-event-tracking-clicked_result="${data.title}">
                                            ${ /* hack to show only the title in the autocomplete (e.g. when pressing enter) */'' }
                                            <div hidden>${data.title}</div>
                                            <div class='autocomplete-image__img-container'>
                                                <div class='ratio ratio-1x1'>
                                                    ${ /* use thumbnail "autocomplete-image" */'' }
                                                    <img class="ratio-item" src='${data.imageUrl}' alt="">
                                                </div>
                                            </div>
                                            <div class='autocomplete-image__text'>
                                                <a href="${data.url}" class="font-bold stretch-link__link">
                                                <span>${data.title.replace(regexp, '<mark>$&</mark>')}</span>
                                                <span class="autocomplete-image__info-text">
                                                     <span class="mt-3 strong text-muted">${data.category ? "<br/>" + data.category.replace(regexp, '<mark>$&</mark>') : ""}</span> 
                                                    <span class="icon icon-minus align-middle text-muted"></span>
                                                    <span class="mt-3 strong text-muted"> ${data.date ? data.date.replace(regexp, '<mark>$&</mark>') : ""}</span> 
                                                   
                                                </span>
                                               </a>
                                            </div>
                                        </div>`
                                        }
                                    } else if (data.type === "searchSuggestion"){
                                        return `<a  href="${data.url}" class="autocomplete__suggestion autocomplete__suggestion--top autocomplete__selectable js-autocomplete__item js-tracking-event"
                                                 data-event-tracking-event="search_select" 
                                                 data-event-tracking-search_string="${currentValue}"
                                                 data-event-tracking-clicked_result="${data.title}">
                                                ${data.title.replace(regexp, '<mark>$&</mark>')}
                                                 <span class="icon icon-search"></span>
                                            </a>`
                                    } else {
                                        return `<a  href="${data.url}" class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item js-tracking-event"
                                                 data-event-tracking-event="search_select" 
                                                 data-event-tracking-search_string="${currentValue}"
                                                 data-event-tracking-clicked_result="${data.title}">
                                                ${data.title.replace(regexp, '<mark>$&</mark>')}
                                            </a>`
                                    }

                                }

                            }
                        }).join('');


                        let categoryOverviewUrl = "";
                        if (category.overviewUrl) {
                            categoryOverviewUrl = category.overviewUrl
                                + (category.overviewUrl.indexOf('?') >= 0 ? '&' : '?')
                                + 'q=' + currentValue;
                        }

                        let counter = categoryItems.counter;


                        let header = `<div class="autocomplete__dataset-header">
                                            <a href="${categoryOverviewUrl}" class="autocomplete__dataset-header-hl hover-primary">
                                           <span class="h4 mb-0">${category.title}</span><span class="autocomplete__counter">${counter}</span></a> 
                                      </div>`;

                        let suggestionHeader = `<div class="autocomplete__dataset-header--suggestion">
                                            <div class="autocomplete__dataset-header-hl hover-primary">
                                           <span class="h4 mb-3">${category.title}</span></div> 
                                      </div>`;


                        if (categoryItems.counter>0) {
                            if (category.name === 'searchSuggestions' && !isFrequentlySearched) {
                                topItems.push(`<div class="d-flex flex-wrap">${tempItems}</div>`);
                            } else if (category.name === 'searchSuggestions' && isFrequentlySearched) {
                                topItems.push(`${suggestionHeader}<div class="d-flex flex-wrap">${tempItems}</div>`);
                            } else {
                                if (category.name === 'bestOff' && isFrequentlySearched) {
                                    middleItems.push(`${suggestionHeader}<div class="autocomplete__dataset">${tempItems}</div>`);
                                }else if (category.name === 'bestOff' && !isFrequentlySearched) {
                                    middleItems.push(`<div class="autocomplete__dataset">${tempItems}</div>`);
                                }else if(category.name === autocompleteType){
                                    middleItems.push(`<div class="autocomplete__dataset">${header}${tempItems}</div>`);

                                } else {
                                    bottomItems.push(`<div class="autocomplete__dataset">${header}</div>`);
                                }
                            }
                        }
                    }
                }); // end of categories.map

                // checking if we have results if we don't
                // take data from the noResults callback


                return matches === 0
                    ? template
                    : `<li class="autocomplete__top">${topItems.join('')}</li>
                        <li class="autocomplete__bottom">
                            <div class="row gy-36">
                                <div class="col-12">${middleItems.join('')}</div>
                                <div class="col-12">${bottomItems.join('')}</div>
                            </div>
                        </li>`;
            },
            onOpened: ({results}) => {
                initInScope(results);
            },
        });
    });
}
